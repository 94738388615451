import {
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import {
  formatIconMultipleData,
  getRequiredFields,
  serializeToImageObject
} from '../../../modules/handle-sanity-data';
import { IconMultiple } from '../../icon-multiple';
import { ImageWithText } from '../../image-with-text';
import { PortableTextComponent } from '../../portable-text';
import { StepHeadline, StepText, StepWrapper } from './elements';

export const HowItWorksSection3 = ({ data }: { data: Record<string, any> }) => {
  const theme = ownUpWhiteTheme;
  const types: string[] = ['heading', 'tagline', 'body', 'mainImage', 'section'];
  const content = data ? getRequiredFields(data, types) : {};
  const IconMultipleValueProps = formatIconMultipleData(content?.sectionObj);

  const title = content?.headingObj?.heading;
  const headline = content?.taglineObj?.tagline;
  const body = content?.bodyObj?.body;
  const mainImage = content?.mainImageObj;

  const imageOptions = { placeholder: 'blurred', style: { width: '100%' } };
  const image = serializeToImageObject(mainImage, imageOptions);

  return (
    <OwnUpThemeProvider theme={theme}>
      <StepWrapper>
        <OwnUpGridWrapper>
          <ImageWithText {...{ title, image }}>
            <StepHeadline>{headline}</StepHeadline>
            {body && (
              <StepText variant="body2">
                <PortableTextComponent blocks={body} />
              </StepText>
            )}
          </ImageWithText>
        </OwnUpGridWrapper>
        <IconMultiple ariaLabel={headline} theme={theme} valueProps={IconMultipleValueProps} />
      </StepWrapper>
    </OwnUpThemeProvider>
  );
};
