import {
  OwnUpBody,
  OwnUpSmallHeadlineBook,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ImageWithTextHeadlineWrapper } from '../../image-with-text';
import { OverlineText, OverlineWrapper } from '../../overline-text';

const Step2TitleWords = 'STEP TWO';

const StyledStep2TitleDesktop = styled(OverlineWrapper)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
  justify-content: inherit;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2.5)}px;
`;

export const Step2TitleDesktop = () => (
  <StyledStep2TitleDesktop>
    <OverlineText>{Step2TitleWords}</OverlineText>
  </StyledStep2TitleDesktop>
);

const StyledStep2TitleMobile = styled(OverlineWrapper)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
  width: 100%;
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
`;

export const Step2TitleMobile = () => (
  <StyledStep2TitleMobile>
    <OverlineText>{Step2TitleWords}</OverlineText>
  </StyledStep2TitleMobile>
);

export const StepHeadline = ({ children }: PropsWithChildren<{}>) => (
  <ImageWithTextHeadlineWrapper>
    <OwnUpSmallHeadlineBook variant="h1">{children}</OwnUpSmallHeadlineBook>
  </ImageWithTextHeadlineWrapper>
);

export const StepText = styled(OwnUpBody)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

export const StepWrapper = styled.section`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8)}px;
`;
