import { OwnUpSmallHeadlineBook } from '@rategravity/own-up-component-library';
import React from 'react';
import { formatIconMultipleData, getRequiredFields } from '../../../modules/handle-sanity-data';
import { IconMultiple } from '../../icon-multiple';

import { OverlineText, OverlineWrapper } from '../../overline-text';
import { Headline } from './elements';

export const StepHeader = ({
  highlightText,
  headlineText
}: {
  highlightText: string | undefined;
  headlineText: string | undefined;
}) => (
  <React.Fragment>
    <OverlineWrapper>
      <OverlineText>{highlightText}</OverlineText>
    </OverlineWrapper>
    <Headline>
      <OwnUpSmallHeadlineBook variant="h2">{headlineText}</OwnUpSmallHeadlineBook>
    </Headline>
  </React.Fragment>
);

export const HowItWorksSection2 = ({ data }: { data: Record<string, any> }) => {
  const types: string[] = ['heading', 'tagline', 'section'];
  const content = data ? getRequiredFields(data, types) : {};
  const IconMultipleValueProps = formatIconMultipleData(content?.sectionObj);

  return (
    <IconMultiple
      ariaLabel={content?.headingObj?.heading || 'Step one'}
      sectionHeader={
        <StepHeader
          highlightText={content?.headingObj?.heading || 'STEP ONE'}
          headlineText={content?.taglineObj?.tagline || 'Build your profile'}
        />
      }
      valueProps={IconMultipleValueProps}
    />
  );
};
