import React from 'react';
import { getRequiredFields, serializeToImageObject } from '../../../modules/handle-sanity-data';
import { Body, Header } from '../../header';

export const HowItWorksSection1 = ({ data }: { data: Record<string, any> }) => {
  const types: string[] = ['heading', 'tagline', 'mainImage'];
  const content = data ? getRequiredFields(data, types) : {};
  const imageOptions = { placeholder: 'blurred', style: { width: '100%' } };
  const { headingObj, taglineObj, mainImageObj } = content;
  return (
    <Header
      image={serializeToImageObject(mainImageObj, imageOptions)}
      headerText={headingObj?.heading || ''}
    >
      {taglineObj?.tagline && <Body>{taglineObj.tagline}</Body>}
    </Header>
  );
};
