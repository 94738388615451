import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  IconLink,
  OwnUpBody,
  OwnUpGridWrapper,
  OwnUpSmallHeadlineMedium
} from '@rategravity/own-up-component-library';
import { ownUpLightTheme, OwnUpThemeProvider } from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import { Link } from 'gatsby';
import React from 'react';
import { formatIconMultipleData, getRequiredFields } from '../../../modules/handle-sanity-data';
import { IconMultipleInner } from '../../icon-multiple';

import {
  CallToActionSection,
  DesktopDisplay,
  IconMultipleWrapper,
  LetsTalkButton,
  MobileDisplay,
  MobileNeedMoreKnowledge,
  Section
} from './elements';

const CheckOutOurFaqs = () => {
  const trackingInstance = useTrackingInstance();
  return (
    <IconLink
      icon={ArrowRightIcon}
      iconPlacement="right"
      link="/faq"
      component={Link}
      to="/faq"
      style={{ margin: '0 auto' }}
      onClick={() =>
        trackingInstance.track('clickFaqCTA-howItWorks', {
          'page-location': 'how-it-works-check-out-our-faqs-link'
        })
      }
    >
      Check out our FAQs
    </IconLink>
  );
};

export const HowItWorksSection5 = ({ data }: { data: Record<string, any> }) => {
  const trackingInstance = useTrackingInstance();
  const types: string[] = ['heading', 'tagline', 'section', 'callToAction'];
  const content = data ? getRequiredFields(data, types) : {};
  const IconMultipleValueProps = formatIconMultipleData(content?.sectionObj);
  const headerA = content?.headingObj?.heading;
  const headerB = content?.taglineObj?.tagline;

  return (
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      <Section aria-label={headerA}>
        <OwnUpGridWrapper>
          <DesktopDisplay>
            <OwnUpSmallHeadlineMedium variant="h2">{headerA}</OwnUpSmallHeadlineMedium>
            <OwnUpSmallHeadlineMedium variant="h2">{headerB}</OwnUpSmallHeadlineMedium>
          </DesktopDisplay>
          <MobileDisplay>
            <OwnUpSmallHeadlineMedium variant="h2">
              {headerA} {headerB}
            </OwnUpSmallHeadlineMedium>
          </MobileDisplay>
          <IconMultipleWrapper>
            <IconMultipleInner
              ariaLabel="existing offer value props"
              valueProps={IconMultipleValueProps}
            />
          </IconMultipleWrapper>
          <CallToActionSection>
            <LetsTalkButton
              component={Link}
              to={content?.callToActionObj?.url}
              id="get_started_hiw"
              onClick={() =>
                trackingInstance.track('clickDiscoverCTA-howItWorks', {
                  'page-location': 'how-it-works-loan-offer'
                })
              }
            >
              {content?.callToActionObj?.linkText}
            </LetsTalkButton>
            <DesktopDisplay>
              <OwnUpBody component="span" variant="body1">
                Need more knowledge? <CheckOutOurFaqs />
              </OwnUpBody>
            </DesktopDisplay>
            <MobileDisplay>
              <MobileNeedMoreKnowledge>
                <OwnUpBody variant="body1">Need more knowledge?</OwnUpBody>
                <CheckOutOurFaqs />
              </MobileNeedMoreKnowledge>
            </MobileDisplay>
          </CallToActionSection>
        </OwnUpGridWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
