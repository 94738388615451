import { OwnUpFillButtonSecondary, PropsWithTheme } from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const Section = styled.section`
  text-align: center;

  background-color: ${({ theme }: PropsWithTheme<{}>) =>
    theme.backgroundColors.primaryTheme?.backgroundColor};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;

export const DesktopDisplay = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const MobileDisplay = styled.div`
  display: 'auto';

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const LetsTalkButton = styled(OwnUpFillButtonSecondary)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
    width: 100%;
    text-align: center;
  }

  margin: auto;
  width: fit-content;
`;

export const CallToActionSection = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0)};
  }
`;

export const MobileNeedMoreKnowledge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1)}px;
`;

export const IconMultipleWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6)}px;
`;
