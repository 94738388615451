import { GuidesSection } from '@rategravity/marketing-components';
import { graphql } from 'gatsby';
import React from 'react';
import {
  HowItWorksSection1,
  HowItWorksSection2,
  HowItWorksSection3,
  HowItWorksSection4,
  HowItWorksSection5,
  HowItWorksSection6
} from '../components/how-it-works';
import { Layout } from '../components/layout';
import { getPageSections } from '../modules/handle-sanity-data';
import { QueryData } from '../modules/sanity-data-types';

export const pageQuery = graphql`
  query {
    pages: allSanityPage(filter: { title: { eq: "How It Works" } }) {
      nodes {
        pageBuilder {
          name
          _rawPageBuilder(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  }
`;

export interface PageProps {
  data: QueryData;
}

const SectionComponents = [
  HowItWorksSection1,
  HowItWorksSection2,
  HowItWorksSection3,
  HowItWorksSection4,
  HowItWorksSection5,
  HowItWorksSection6
];

export const HowItWorks = ({ data }: PageProps) => {
  const pageSections: Record<string, any> = getPageSections(data);

  const Sections = () => (
    <React.Fragment>
      {Array.from(Array(8).keys()).map((i, index) => {
        const Section = SectionComponents[i];
        return (
          pageSections[`section${i + 1}`] && (
            <Section key={`section-${index}`} data={pageSections[`section${i + 1}`]} />
          )
        );
      })}
    </React.Fragment>
  );

  return (
    <Layout>
      <Sections />
      <GuidesSection />
    </Layout>
  );
};

export default HowItWorks;
