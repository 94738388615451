import { useTrackingInstance } from '@rategravity/1pt-lib';
import { FOG_20, OwnUpFillButtonSecondary } from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React, { useCallback } from 'react';
import { formatImageMultipleData, getRequiredFields } from '../../../modules/handle-sanity-data';
import { PureImageMultiple } from '../../image-multiple';

const GetStartedButton = ({ text, link }: { text: string; link: string }) => {
  const tracker = useTrackingInstance();
  const onClick = useCallback(() => {
    tracker.track('Click how it works CTA', {
      'page-location': 'home-learn-how'
    });
  }, [tracker]);

  return (
    <OwnUpFillButtonSecondary component={Link} to={link} onClick={onClick} id="get_started_hiw">
      {text}
    </OwnUpFillButtonSecondary>
  );
};

export const HowItWorksSection4 = ({ data }: { data: Record<string, any> }) => {
  const types: string[] = ['heading', 'tagline', 'callToAction', 'section'];
  const content = data ? getRequiredFields(data, types) : {};
  const ImageMultipleValueProps = formatImageMultipleData(content?.sectionObj);

  const heading = content?.headingObj?.heading || '';
  const tagline = content?.taglineObj?.tagline || '';
  return (
    <PureImageMultiple
      button={
        <GetStartedButton
          text={content?.callToActionObj?.linkText}
          link={content?.callToActionObj?.url}
        />
      }
      highlightText={heading}
      headlineText={tagline}
      backgroundColor={FOG_20}
      valueProps={ImageMultipleValueProps}
    />
  );
};
